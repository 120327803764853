<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="openFullscreen"
    :hide-overlay="openFullscreen"
    content-class="cw-business-loan-signees-dialog"
    max-width="800"
    persistent
  >
    <component
      :is="dialogComponent"
      v-if="dialogComponent"
      v-bind="{
        data: {
          ...dialogProps,
          openFullscreen,
        },
      }"
    />
  </v-dialog>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import { goToError } from '@shared/mixins';

export default {
  name: 'CwBusinessLoanSigneesDialog',

  components: {
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    dialog: false,
    dialogFields: null,
    dialogProps: {},
    dialogType: null,
  }),

  computed: {
    ...mapState({
      apiErrors: state => state.application.apiErrors,
    }),

    dialogComponent() {
      if (!this.dialogType) return null;

      const type = this.dialogType[0].toUpperCase() + this.dialogType.slice(1);
      return () => import('@fi/pages/steps/businessLoan/BusinessLoanSigneesDialog' + type + 'Fields'); // eslint-disable-line
    },

    openFullscreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  mounted() {
    this.$bus.$on('SIGNEES_DIALOG_CLOSE', () => this.onDialogClose());
    this.$bus.$on('SIGNEES_DIALOG_OPEN', ({ type, data }) => this.onDialogOpen({ type, data }));
  },

  methods: {
    onDialogClose() {
      this.dialog = false;
    },

    async onDialogOpen({ type, data }) {
      this.dialogType = null;
      await this.$nextTick();
      this.dialogType = type;
      this.dialogProps = data;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss">
.cw-business-loan-signees-dialog {
    &__toolbar {
      .v-toolbar__content {
        padding: 0 28px;
      }
    }
  }
</style>
